.dashboard-content {
  
  h1, h2, h3, h4, h5, h6 {
    color: $headerTextColor;
  }

  a {
  
    color: $primaryLinkColor;
    text-decoration: none;
  
    &:hover {
      color: $primaryLinkHoverColor;
      text-decoration: underline;
    }
  
  }

  .page-title {
    color: $primaryTextColor !important;
    font-size: 1.5rem;
  } 

  .summary {

    .summary-title {
      font-size: 1.5rem;
    }

  }

}
