/* Cards */
.card {

    background-color: rgba(255, 255, 255, 0.04);
    border-radius: 16px;
    font-size: $wvFontSize;
    margin-bottom: 1rem;

    a {
        color: $wvPrimaryLinkColor;
    }
    
    .card-header {
        
        border-bottom: 0;
        color: $wvPrimaryTextColor; 
        padding: $wvGutter $wvGutter calc($wvGutter/2) $wvGutter;

        .card-title {
            font-size: $wvFontSize;
            font-weight: 600;
            margin-bottom: 0;
        }

        i {
            font-size: $wvFontSize;
            margin-right: 5px;
        }

    }
    
    .card-body {
        color: $wvPrimaryTextColor; 
        font-size: $wvFontSize;
        font-weight: 300;
        padding: $wvGutter;
    }
    
    .card-header + .card-body {
        padding: calc($wvGutter/2) $wvGutter $wvGutter $wvGutter;
    }

    &.muted {

        background-color: rgba(22, 24, 47, 0.8);

        .card-title {
            color: $wvTextMutedColor;
        }

    }

    &.border-none {
        border: none;
    }

}
