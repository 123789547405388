.mat-mdc-progress-spinner {

    &.mat-primary {

        .mdc-circular-progress__determinate-circle, 
        .mdc-circular-progress__indeterminate-circle-graphic {
            stroke: $primaryColor;
        }
        
    }

    &.mat-button {

        .mdc-circular-progress__determinate-circle, 
        .mdc-circular-progress__indeterminate-circle-graphic {
            stroke: $buttonSpinnerColor;
        }
        
    }

}
