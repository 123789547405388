.ptl-theme.webview {
  background: #171B45;
  /* Cards */
}
.ptl-theme.webview .mdc-button.mat-mdc-unelevated-button.mat-secondary {
  background-color: #E6996D;
  color: #000;
}
.ptl-theme.webview .mdc-button.mat-link {
  color: #68C9F3;
}
.ptl-theme.webview .card {
  background-color: rgba(255, 255, 255, 0.04);
  border-radius: 16px;
  font-size: 1.15rem;
  margin-bottom: 1rem;
}
.ptl-theme.webview .card a {
  color: #68C9F3;
}
.ptl-theme.webview .card .card-header {
  border-bottom: 0;
  color: #fff;
  padding: 1rem 1rem 0.5rem 1rem;
}
.ptl-theme.webview .card .card-header .card-title {
  font-size: 1.15rem;
  font-weight: 600;
  margin-bottom: 0;
}
.ptl-theme.webview .card .card-header i {
  font-size: 1.15rem;
  margin-right: 5px;
}
.ptl-theme.webview .card .card-body {
  color: #fff;
  font-size: 1.15rem;
  font-weight: 300;
  padding: 1rem;
}
.ptl-theme.webview .card .card-header + .card-body {
  padding: 0.5rem 1rem 1rem 1rem;
}
.ptl-theme.webview .card.muted {
  background-color: rgba(22, 24, 47, 0.8);
}
.ptl-theme.webview .card.muted .card-title {
  color: rgba(255, 255, 255, 0.6);
}
.ptl-theme.webview .card.border-none {
  border: none;
}
.ptl-theme.webview .expansion-panel {
  height: 70px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ptl-theme.webview .expansion-panel.expanded {
  display: block;
  height: auto;
}
.ptl-theme.webview .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle, .ptl-theme.webview .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
.ptl-theme.webview .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #fff !important;
}
.ptl-theme.webview .mdc-button.mat-link {
  color: #68C9F3;
}
.ptl-theme.webview label {
  color: #fff;
}
.ptl-theme.webview .text-link {
  color: #E6996D;
}
.ptl-theme.webview strong,
.ptl-theme.webview b,
.ptl-theme.webview .font-weight-bold {
  font-weight: 700;
}