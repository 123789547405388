@import 
    '../breakpoints', 
    '../typography'
;

html, body {
    color: $primaryTextColor;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}

.ptl-theme {

    @import 
        '../utils',
        'buttons',
        'layout',
        'cards',
        'forms',
        'alerts',
        'summary-grid',
        'material-components'
    ;

}
