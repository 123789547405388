/**
 * MFRM Portal Theme
 */
@import 
    'portal-theme-variables',
    'webview-theme-variables',
    '../../../assets/styles/portal-theme-base/portal-theme-base',
    '../../../assets/styles/buttons'
;

.ptl-theme {
    
    background-color: $pageBackgroundColor;

    &.webview {

        background: #171B45;

        .cdk-overlay-backdrop {
            background-color: #00000055;
        }

        .mat-mdc-radio-button .mdc-radio {
        
            .mdc-radio__native-control:enabled {

                &:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle,
                &:checked+.mdc-radio__background .mdc-radio__outer-circle,
                +.mdc-radio__background .mdc-radio__inner-circle {
                    border-color: #fff !important;
                }

            }

        }

        .mdc-button.mat-mdc-unelevated-button.mat-secondary {
            background-color: #FBD273;
            color: #000;
        }

        label {
            color: #fff;
        }

        .text-link {
            color: $wvPrimaryLinkColor;
        }

        strong,
        b,
        .font-weight-bold {
            font-weight: 700;
        }

    }

}

.no-scroll {
    overflow-y: hidden;
}
