@function contrastText($color) {
    $result: #000;
    $lightness: lightness($result);
    @if ($lightness > 50) {
        $result: #fff;
    }
    @return $result;
}

.strong {
    font-weight: 600;
}

.mt-auto {
    margin-top: auto;
}

.ml-auto {
    margin-left: auto;
}

.center-xy {
    display: flex;
    justify-content: center;
    align-content: center;
}

.center-y {
    display: flex;
    align-items: center;
}

.center-x {
    display: flex;
    justify-content: center;
}

.text-right {
    text-align: right;
}
