@mixin btnLink {
    background-color: transparent;
    border: none;
    color: $primaryLinkColor;
    font-weight: 400;
    padding: 0;
    text-decoration: none;
}

a.mdc-button,
.mdc-button {

    border-radius: $buttonBorderRadius;
    letter-spacing: normal;
    line-height: 1;

    &:disabled {
        opacity: 0.7;
    }

    &:hover {
        text-decoration: none;
    }

    &.mat-mdc-unelevated-button {

        &.mat-primary {

            background-color: $buttonPrimaryBgColor; 
            color: $buttonPrimaryTextColor; 

            &:hover {
                background-color: darken($buttonPrimaryBgColor, 5%);
            }

        }

        &.mat-secondary {

            background-color: $buttonSecondaryBgColor; 
            color: $buttonSecondaryTextColor; 

            &:hover {
                background-color: darken($buttonSecondaryBgColor, 5%);
            }

        }
    
    }

    &.mdc-button--outlined {

        border-radius: $buttonBorderRadius;

        &.mat-primary {
            border: 1px solid $buttonPrimaryBgColor;
            color: $buttonPrimaryBgColor; 
        }
    
        &.mat-secondary {
            border: 1px solid $buttonSecondaryBgColor;
            color: $buttonSecondaryBgColor; 
        }

    }

    &.btn-fluid {
        width: 100%;
    }

    &.nowrap .mdc-button__label {
        white-space: nowrap;
    }

}


a.mat-flat-button,
.mat-flat-button {

    border-radius: $buttonBorderRadius;
    font-size: 1rem;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.mat-primary {
        background-color: $buttonPrimaryBgColor; 
        color: $buttonPrimaryTextColor; 
    }

    &.mat-secondary {
        background-color: $buttonSecondaryBgColor; 
        color: $buttonSecondaryTextColor; 
    }

    &:hover {
        text-decoration: none;
    }

    .mat-progress-spinner circle, .mat-spinner circle {
        stroke: #fff;
    }  

    &.btn-fluid {
        width: 100%;
    }

    &.btn-wide {
        padding-left: 30px;
        padding-right: 30px;
    }

}

.mat-stroked-button {

    border-radius: $buttonBorderRadius;

    &.mat-primary {
        border: 1px solid $buttonPrimaryBgColor;
        color: $buttonPrimaryBgColor; 
    }

    &.mat-secondary {
        border: 1px solid $buttonSecondaryBgColor;
        color: $buttonSecondaryBgColor; 
    }

}

.mat-button {

    border-radius: $buttonBorderRadius;

    &.mat-link {
        @include btnLink;
    }

    &.mat-text {
        color: $primaryTextColor;
    }

    &.mat-danger {
        color: $errorColor;
    }

    &:disabled {
        opacity: 0.7;
    }

}

.mat-icon-button {

    background-color: transparent;
    
    &.mat-primary {

        i {
            color: $primaryColor;
        }

    }

    &:disabled {
        opacity: 0.3;
    }

}

.btn {

    &.btn-link {

        @include btnLink;

        &:hover {
            text-decoration: underline;
        }

    }

}
