.page {

    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;

    .page-content {
    
        margin: 100px 0 15px;

        flex-grow: 2;
        padding-left: 15px;    
        padding-right: 15px;    
        max-width: 360px;
        width: 100%;
    
        display: flex;
        flex-direction: column;
        align-items: center;

        &.page-content-fluid {
            max-width: 100%;
        }

        &.page-content-wide {
            max-width: 1140px;
        }

        .content-compact {
            max-width: 360px;
        }

        > * {
            width: 100%;
        }

        .logo {
            margin: 0 0 30px;
            max-width: 280px;
            width: 100%;
        }

        form {
            width: 100%;
        }

    }

    .page-title {
        text-align: center;
    }

    .page-footer {
        background-color: $footerBgColor;
        margin: auto;
        width: 100%;
    }

}

pre {
    background-color: #fff;
    border: 1px dashed #000;
    border-radius: 4px;
    color: #000;
    font-size: 1rem;
    padding: 10px;
}

@media (max-width: $breakpointXsMax) {

    .page {

        .page-content {
        
            margin-top: 30px;
            
            .logo {
                width: 65%;
            }

        }

    }

}
