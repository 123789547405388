.mat-mdc-radio-button .mdc-radio {
        
    .mdc-radio__native-control:enabled {

        &:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle,
        &:checked+.mdc-radio__background .mdc-radio__outer-circle,
        +.mdc-radio__background .mdc-radio__inner-circle {
            border-color: #fff !important;
        }

    }

}
