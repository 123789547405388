.form-control-date-range-picker {

    border: 1px solid #ced4da;
    border-radius: 4px;
    height: 35.5px;
    padding-left: 10px;

    .mat-icon-button {
        transform: translateY(-3px);
    }

    > .mat-date-range-input {
        display: flex !important;
        align-items: center !important;
    }
    
    .mat-mdc-icon-button.mat-mdc-button-base {
        transform: translate(5px, -10px);
    }

}

.form-group {
    
    margin-bottom: 15px;

    label {
        margin-bottom: 10px;
    }

    .form-control.is-invalid {
        background-image: none;
    }
    
}

.btn-checkbox {

    border: 1px solid $primaryColor;
    color: $primaryColor;
    border-radius: 20px;
    font-weight: normal;

    .mat-checkbox-frame {
        border-color: $primaryColor;
    }

    label {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        color: $primaryColor;
        padding-right: 10px;
        display: flex;
        align-items: center;
        align-self: stretch;
    }

}
