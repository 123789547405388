
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-stretch: 100%;
    src: url(/assets/fonts/OpenSans/OpenSans-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    src: url(/assets/fonts/OpenSans/OpenSans-Regular.ttf) format('truetype');
}
  
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    src: url(/assets/fonts/OpenSans/OpenSans-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-stretch: 100%;
    src: url(/assets/fonts/OpenSans/OpenSans-Bold.ttf) format('truetype');
}
 