html, body {
    font-size: 14px;
}

.dialog-no-sleep-record .mat-dialog-container {

    background-color: #eee;
    border-radius: 10px;

    .card {
        border-radius: 10px;
        width: 100%;
    }

}

.dashboard-theme {
 
    height: 100%;
    width: 100%;

    @import 
        '../utils',
        'material-components',
        'alerts',
        'layout',
        'charts',
        'forms',
        'cards',
        'typography',
        'material-components',
        'buttons'
    ;

}

@media (max-width: $breakpointMdMax) {

    .dashboard-theme {

        &.menu-open {
            overflow: hidden;
        }

    }

}
