.card {

    border: none;
    border-radius: 8px;
    height: 100%;

    .card-header {

        background-color: transparent;
        padding: 1rem;
        
        &.no-border {
            border-bottom: none;
            padding-bottom: 0;
        }

        .card-title {
            font-size: 1.2rem;
            margin-bottom: 0;
        }

    }

    .card-title {
        color: $headerTextColor;
    }

}
