// colors
$primaryColor: #6375ec;
$secondaryColor: #FFCC70;
$errorColor: #d7253d;
$warningColor: rgb(255, 193, 7);
$successColor: #36be21;
$infoColor: #6dbae6;

// page
$contentBgColor: #f5f5f5;

// header
$headerBgColor: #070A18;
$headerTextColor: darken($primaryColor, 30%);

// navigation
$navBgColor: #070A18;

$navItemBgColor: transparent;
$navItemTextColor: #fff;
$navItemActiveBgColor: $primaryColor;
$navItemActiveTextColor: #fff;
$navItemHoverBgColor: $primaryColor;
$navItemHoverTextColor: #FDF0E4;

$subnavItemBgColor: #05212E;
$subNavItemTextColor: #fff;
$subnavItemActiveBgColor: #05212E;
$subnavItemActiveTextColor: lighten($primaryColor, 10%);
$subnavItemHoverBgColor: #05212E;
$subnavItemHoverTextColor: lighten($primaryColor, 10%);

// links
$primaryLinkColor: $primaryColor;
$primaryLinkHoverColor: $primaryColor;

// page content
$primaryTextColor: #222;

// buttons
$buttonPrimaryBgColor: $secondaryColor;
$buttonPrimaryTextColor: #070A18;
$buttonSecondaryBgColor: $primaryColor;
$buttonSecondaryTextColor: #fff;
$buttonBorderRadius: 50px;
$buttonSpinnerColor: $buttonPrimaryTextColor;

// alerts
$alertDangerBackgroundColor: $errorColor;
$alertDangerTextColor: #fff;
$alertSuccessBackgroundColor: $successColor;
$alertSuccessTextColor: #fff;
$alertInfoBackgroundColor: $infoColor;
$alertInfoTextColor: #fff;

// charts
$chartBarColorStart: #6137F1;
$chartBarColorEnd: #9E57E0;
$chartCircleColorStart: #6137F1;
$chartCircleColorEnd: #9E57E0;
