.alert {

    &.alert-danger {
        background-color: $alertDangerBackgroundColor;
        border-color: $alertDangerBackgroundColor;
        color: $alertDangerTextColor;
    }

    &.alert-success {
        background-color: $alertSuccessBackgroundColor;
        border-color: $alertSuccessBackgroundColor;
        color: $alertSuccessTextColor;
    }

}