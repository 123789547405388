.mdc-button {

    &.btn-processing {
        
        .mdc-button__label {
            position: relative;
        }

        .btn-label {
            opacity: 0;
        }

        .btn-spinner {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

    }

    &.w-wide {
        padding-left: 30px;
        padding-right: 30px;
    }

}

ptl-button {

    width: 100%;
    
    &.w-auto {
        width: auto;
    }
    
    &.w-wide {
    
        width: auto;
        
        > button {
            padding-left: 30px;
            padding-right: 30px;
        }
        
    }
        

}
