.mat-mdc-table {

    .mat-mdc-header-cell {
        background-color: $primaryColor;
        color: #fff;
        font-size: 1rem;
    }

}

@media (max-width: $breakpointMdMax) {

    .mat-mdc-table.table-responsive {

        thead {
            display: none;
        }

        .mat-mdc-row {

            border-top: 1px solid #ccc;
            display: block;
            height: auto;
            padding-bottom: 10px;
            padding-top: 10px;
            
            &:first-child {
                border-top: none;
                padding-top: 0;
            }

            .mat-mdc-cell {

                color: #999;
                display: block;
                border: none;
                padding: 2.5px 10px;
                width: 100%;

                &[title]:before {
                    content: attr(title);
                    font-weight: 500;
                    width: 100px;
                    display: inline-block;
                }

            }

        }

    }

}
