.mat-mdc-radio-button {

    &.readonly {

        opacity: 1;
        
        .mdc-radio {
            
            .mdc-radio__native-control:disabled {

                &:checked+.mdc-radio__background {

                    .mdc-radio__outer-circle {
                        opacity: 1;
                        border-color: #0000008a;
                    }
                    
                    .mdc-radio__inner-circle {
                        opacity: 1;
                        border-color: $primaryColor;
                    }

                }
                
                &:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
                    opacity: 1;
                    border-color: #0000008a;
                }
                
            }
            
        }

        label {
            color: $primaryTextColor;
        }
        
    }

}
