// colors
$wvPrimaryColor: #FBD273;

// text
$wvFontSize: 1.15rem;

// page
$wvPageBgColor: #171B45;
$wvGutter: 1rem;

// links
$wvPrimaryLinkColor: $wvPrimaryColor;
$wvPrimaryLinkHoverColor: darken($wvPrimaryLinkColor, 10%);

// content
$wvPrimaryTextColor: #fff;
$wvTextMutedColor: rgba(255, 255, 255, 0.6);

// buttons
$wvButtonPrimaryBgColor: $wvPrimaryColor;
$wvButtonPrimaryHoverBgColor: darken($wvPrimaryColor, 10%);
$wvButtonPrimaryTextColor: #000;
$wvButtonPrimaryDisabledBgColor: rgba(255, 255, 255, 0.04);
$wvButtonPrimaryDisabledBorderColor: rgba(255, 255, 255, 0.2);
