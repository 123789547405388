.ptl-theme {

    &.webview {

        background: $wvPageBgColor;

        @import 
            'buttons',
            'cards',
            'dialog',
            'expansion-panel',
            'radios',
            'typography'
        ;

    }

}
