/**
 *  SleepScore Portal Dashboard Theme
 */
 @import 
 '../../../assets/styles/breakpoints',
 'dashboard-theme-variables',
 '../../../assets/styles/dashboard-theme-base/dashboard-theme-base',
 '../../../assets/styles/buttons'
;


.dashboard-theme {
    background-color: $contentBgColor;
}
