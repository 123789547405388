$borderRadius: 4px;
$leftColumnWidth: 240px;

.dashboard {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.dashboard-header {
    
    background-color: $headerBgColor;
    color: $headerTextColor;
    min-height: 60px;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    align-items: center;

    .row {
        width: 100%;
    }

    > .col {

        display: flex;
        align-items: center;

        &:nth-child(2) {
            justify-content: center;
        }

        &:nth-child(3) {
            justify-content: flex-end;
        }


    }

    .logo {
    
        height: auto;
        width: $leftColumnWidth - 30;
        text-align: center;
    
        img {
            max-width: 120px;
        }

    }

    .mdc-icon-button,
    .mdc-button {
        background-color: transparent;
        color: #fff;
    }

}

.dashboard-container {
    min-height: 100%;
    position: relative;
    width: 100%;
}

.dashboard-nav {
    
    $transitionSpeed: .25s;
    background-color: $navBgColor;
    height: 100%;

    .logo {
    
        display: flex;
        justify-content: center;
        width: auto;
        margin-bottom: 20px;
    
        img {
            min-height: 30px;
            max-width: 120px;
        }

    }

    nav {

        display: flex;
        flex-direction: column;
        height: 100%;

        .nav-menu {
            
            list-style-type: none;
            margin-left: 0;
            padding-left: 0;

            > li {
            
                position: relative;

                > a, 
                > button {

                    background-color: $navItemBgColor;
                    border: none;
                    color: $navItemTextColor;
                    display: flex;
                    align-items: center;
                    font-size: 1rem;
                    font-weight: 300;
                    padding: 7.5px 15px;
                    text-decoration: none;
                    transition: background-color .15s, color .15s;
                    width: 100%;

                    &.active,
                    &.active:focus {
                        background-color: $navItemActiveBgColor;
                        color: $navItemActiveTextColor;
                    }

                    &:hover,
                    &:focus {
                        background-color: $navItemHoverBgColor;
                        color: $navItemHoverTextColor;
                    }

                    &:active {
                        background-color: transparent;
                    }

                }

                .expand-icon {
                    margin-left: auto;
                    transition: transform $transitionSpeed;
                }

                &.open {

                    > button {
                        background-color: $navItemActiveBgColor;
                    }
                    
                    .expand-icon {
                        transform: rotate(180deg);
                    }

                }

            }

        }

        .sub-menu {

            list-style-type: none;
            padding-left: 0;
            transition: all $transitionSpeed;
            overflow: hidden;

            a {
                
                background-color: $subnavItemBgColor;
                color: $subNavItemTextColor;
                display: block;
                font-size: 0.9rem;
                padding: 7.5px 0 7.5px 17px;
                text-decoration: none;

                &.active {
                    background-color: $subnavItemActiveBgColor;
                    color: $subnavItemActiveTextColor;
                }

                &:hover {
                    background-color: $subnavItemHoverBgColor;
                    color: $subnavItemHoverTextColor;
                }

            }

        }

        .item-icon {
            font-size: 1.2rem;
            margin-right: 10px;
            display: flex;
            align-items: center;
            transform: translateY(2px);
        }

        .user-actions {

            margin-bottom: 30px;
            margin-top: auto;

            .btn-user-menu {

                color: $navItemTextColor;
                font-weight: 300;
                width: 100%;

                .mdc-button__label,
                .btn-label {
                    display: flex;
                    align-items: center;
                    width: 100%;
                }

                i:first-child {
                    margin-right: 3px;
                }

                i:last-child {
                    margin-left: auto;
                }

            }

        }

    }

}

.dashboard-content {
    color: $primaryTextColor;
    display: flex;
    height: 100%;
    flex-direction: column;
    flex: 1;
}

.dashboard-page {
    padding: 15px;
}

.dashboard-footer {

    color: $primaryTextColor;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;

    footer {
        margin-bottom: 15px;
        margin-top: 15px;
    }

}

.cards > div {

    display: flex;

    .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card a {
        margin-top: auto;
        max-width: 200px;
    }

}

.page-loader {
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

@media (min-width: $breakpointLgMin) {

    .dashboard-footer,
    .dashboard-page {
        margin-left: $leftColumnWidth;
    }

    .dashboard-header {
        
        display: none;
        justify-content: space-between;

        .nav-toggle {
            display: none;
        }

    }
    
    .dashboard-nav {

        opacity: 1 !important;
        width: $leftColumnWidth;
        position: fixed;
        height: 100%;

        nav .sub-menu a {
            padding-left: 44px;
        }

    }

    .user-menu {

        &.mdc-button {
            display: flex;
        }

        &.mdc-icon-button {
            display: none;
        }

        .label {
            margin-left: 7.5px;
        }

    }

}

@media (max-width: $breakpointLgMax) {

    .user-menu {

        &.mdc-button {
            display: flex;
        }

        &.mdc-icon-button {
            display: none;
        }

    }

}

@media (max-width: $breakpointMdMax) {

    .dashboard-header {
        display: flex;
        justify-content: space-between;
    }

    .dashboard-container {
        position: relative;
    }

    .dashboard-nav {

        display: flex;
        justify-content: center;
        padding-top: 15px;

        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 1000;

        .logo {
            display: none;
        }

        &.mobile-nav-hidden {
            display: none;
        }

        .primary-navigation {
            
            width: 80%;

            .user-actions {
                display: none;
            }

        }

        li a.active {
            border-radius: $borderRadius;
        }

        nav {

            .nav-menu {

                > li {
                    
                    .expand-icon {
                        margin-left: auto;
                    }
        
                    &.open > button {
                        border-radius: $borderRadius;
                    }

                }

            }

            .sub-menu a {
                padding-left: 42px;
            }
       
        }

    }

    .dashboard-footer {
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
        font-size: 0.7rem;
    }

}

@media (max-width: $breakpointSmMax) {

    .user-menu {

        &.mdc-button {
            display: none;
        }

        &.mdc-icon-button {
            display: flex;
        }

    }

    .cards > div + div {
        margin-top: 20px;
    }

}
