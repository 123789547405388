#chart {

    .label-multiple,
    .label-single {
        color: #fff;
        width: 16px;
        height: 16px;
        margin-top: -48px;
        margin-right: -8px;
        float: right;
        cursor: pointer;
        line-height: 1.4;

        display: flex;
        justify-content: center;

    }

    .image-single-source {
        width: 20px;
        height: 20px;
        display: inline-block;
    }

    .label-multiple {
        font-size: 11px;
        font-weight: 600;
        text-align: center;
        border-radius: 100px;
        background-color: #00a5ff;
    }

    .hover-pointer:hover {
        cursor: pointer;
    }
    
}
