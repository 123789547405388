$gutter: 15px;
$gridBorderColor: rgba(255, 255, 255, 0.6);

.summary-grid {

    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    font-size: 1rem;
    padding: $gutter $gutter*2;

    .summary-grid-title {
        font-size: 1.3rem;
        font-weight: 700;
        padding: 0 0 $gutter;
    }

    .row {
        
        margin-left: 0;
        margin-right: 0;

        + .row > [class^="col-"] {
            border-top: 1px solid $gridBorderColor;
            padding: $gutter 0;  
        }

        &:first-child .col {
            border-top: none;
            padding-top: 0;
        }

        &:last-child .col {
            padding-bottom: 0;
        }

    }

}

@media (max-width: $breakpointSmMax) {

    .summary-grid {

        .row:last-child .col:first-child {
            padding-bottom: $gutter;
        }

    }

}

@media (max-width: $breakpointXsMax) {

    .summary-grid {

        background-color: transparent;
        border-radius: 0;
        padding: calc($gutter / 2) 0;

        .summary-grid-title {
            border-bottom: 1px solid $gridBorderColor;;
            font-size: 1.1rem;
            padding-bottom: calc($gutter / 2);
        }

        .row {

            &:last-child .col:first-child {
                padding-bottom: calc($gutter / 2);
            }

            + .row {

                margin-top: 0;

                > [class^="col-"] {
                    border-top: none;
                    padding: calc($gutter / 2) 0;
                }

            }

        }

        &.border-bottom-sm {
            border-bottom: 1px solid $gridBorderColor;
        }

    }
    
}
