@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(/assets/fonts/MaterialIcons/MaterialIconsOutlined-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(/assets/fonts/OpenSans/OpenSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(/assets/fonts/OpenSans/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(/assets/fonts/OpenSans/OpenSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-stretch: 100%;
  src: url(/assets/fonts/OpenSans/OpenSans-Bold.ttf) format('truetype');
}

h1 {
  font-size: 2rem;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

a {
  
  color: $primaryLinkColor;

  &:hover {
    color: $primaryLinkHoverColor;
  }

}

.text-accent {
  color: $accentTextColor;
}

.headlines {

  .headline,
  .headline h1 {
    font-size: 2.3rem;
    font-weight: 300;
    text-align: center;
  }  
  
  .subheadline,
  .subheadline p {
    
    font-size: 1.4rem;
    text-align: center;

    + p {
      margin-top: 1rem;
    }

  }

}

.text-center {
  text-align: center;
}

@media (max-width: $breakpointXsMax) {

  .headlines {

    .headline,
    .headline h1 {
      font-size: 1.3rem;
      font-weight: 300;
    }  
    
    .subheadline,
    .subheadline p {
      font-size: 0.85rem;
    }

  }

  h1 {
    font-size: 1.3rem;
  }

}
