@media (max-width: $breakpointSmMax) {

    .mat-mdc-paginator {

        .mat-mdc-paginator-container {
            justify-content: center;
        }

        .mat-mdc-paginator-range-label {
            margin-left: 0;
        }

    }

}
