.mat-date-range-input {

    .mat-date-range-input-wrapper {
        min-width: 65px;
        text-align: right;
    }

    .mat-date-range-input-end-wrapper {
        text-align: left;
    }

}

.datepicker {

    position: relative;

    .mat-datepicker-toggle {
        position: absolute;
        right: 0;
        top: -10px;
    }

} 
