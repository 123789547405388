.form-group {

    margin-top: 15px;

    label {
        margin-bottom: 5px;
    }

    .invalid-feedback {
        color: $errorTextColor;
    }

    .form-control.is-invalid {
        background-image: none;
        border-color: $errorTextColor;
        border-width: 2px;
        padding: .375rem .75rem;
    }

    .form-select.is-invalid {
        padding: .375rem 2.25rem .375rem .75rem;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
    }

}

.mat-mdc-checkbox {
    
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
        border-color: $labelTextColor !important;
    }
    
    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
        border-color: $labelTextColor !important;
        background-color: transparent;
    }
    
    label {
        color: $labelTextColor;
    }

}

form {

    > .form-group:first-of-type {
        margin-top: 0;
    }

    .mat-form-field {
        width: 100%;
    }

    .mat-button,
    .mat-flat-button {
        width: 100%;
    }

    .form-control:read-only {
        background-color: #fff;
    }

    input,
    select {

        &:disabled {
            opacity: 0.8;
        }

    }

    input.text-center,
    input.text-center::placeholder {
        text-align: center;
    }

    
}

.mat-radio-button {
    
    &.mat-primary {

        .mat-radio-ripple .mat-ripple-element {
            background-color:rgb(255, 255, 255, .26);
        }

        .mat-radio-inner-circle {
            background-color: #fff;
            border-color: #fff;
        }
    
        .mat-radio-checked .mat-radio-outer-circle,
        .mat-radio-outer-circle {
            border-color: #fff;
        }

    }

}

.datepicker {

    position: relative;

    .mat-datepicker-toggle {
        position: absolute;
        right: 0;
        top: -10px;
    }

}
