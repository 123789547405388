$errorColor: #E74E3D;

// page
$pageBackgroundColor: #0B1232;

// buttons
$buttonBorderRadius: 30px;

$primaryButtonBackgroundColor: #686baa;
$primaryButtonTextColor: #fff;
$primaryButtonBorderColor: #fff;

// text
$labelTextColor: #fff;
$errorTextColor: $errorColor;
$accentTextColor: #fff;

// links
$primaryLinkColor: #fff;
$primaryLinkHoverColor: lighten($primaryButtonBackgroundColor, 40%);
$primaryTextColor: #fff;

// footer
$footerBgColor: $pageBackgroundColor;
$footerTextColor: #fff;

// alerts
$alertDangerBackgroundColor: $errorColor;
$alertDangerTextColor: #fff;
$alertSuccessBackgroundColor: $errorColor;
$alertSuccessTextColor: #fff;

// material components
$checkboxBackgroundColor: #fff;
