.mdc-button.mat-link {
    color: $wvPrimaryLinkColor;
}

label {
    color: #fff;
}

.text-link {
    color: $wvPrimaryColor;
}

strong,
b,
.font-weight-bold {
    font-weight: 700;
}
