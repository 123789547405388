.expansion-panel {
            
    height: 70px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    &.expanded {
        display: block;
        height: auto;
    }

}
