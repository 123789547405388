
.mdc-button {

    letter-spacing: normal;

    &:disabled {
        opacity: 0.7;
    }

    &.mat-mdc-unelevated-button {

        border-radius: $buttonBorderRadius;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        &.mat-primary {
        
            background-color: $primaryButtonBackgroundColor;
            border-style: solid;
            border-width: 1px;
            color: $primaryButtonTextColor;
        
            .mat-mdc-progress-spinner {
                
                &.mat-primary circle {
                    stroke: $primaryButtonTextColor;
                }

            }
            
        }

    }

    &.mat-link {
        background-color: transparent;
        border-radius: $buttonBorderRadius;
        width: 100%;
    }

    &.btn-provider {

        .mdc-button__label {
            // display: block;
            position: relative;
            width: 100%;
        }
        
        .btn-label {
            display: block;
            width: 100%;
        }

    }

}
