.mat-mdc-checkbox {

    &.readonly {
        
        opacity: 1;

        .mdc-checkbox__native-control[disabled] {

            &:checked~.mdc-checkbox__background {
                background-color: $primaryColor;
            }
            
            &:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
                border-color: #0000008a;
            }

        }

        label {
            color: $primaryTextColor;
        }

    }

}
