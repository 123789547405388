/**
 * Progress spinner
 */
.mat-mdc-progress-spinner circle, 
.mat-spinner circle {
    stroke: $primaryLinkColor;
}

.mat-spinner,
.mat-progress-spinner {
    
    &.mat-secondary circle {
        stroke: #464B9C;
    }

}

.mat-mdc-progress-spinner {

    &.mat-light {

        .mdc-circular-progress__determinate-circle, 
        .mdc-circular-progress__indeterminate-circle-graphic {
            stroke: #fff;
        }
        
    }

}

/**
 * Material Checkbox
 */
.mat-checkbox {

    &.mat-primary {
    
        .mat-checkbox-frame {
            border-color: $primaryLinkColor !important;
        }

        .mat-checkbox-checkmark .mat-checkbox-checkmark-path {
            stroke: contrastText($checkboxBackgroundColor) !important;
        }

    }

}

.mat-checkbox-checked.mat-primary .mat-checkbox-ripple .mat-ripple-element {
    background-color: $checkboxBackgroundColor !important;
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
    background-color: #fff;
}

.mat-checkbox-layout {

    display: flex;
    white-space: normal !important;

    .mat-checkbox-inner-container {
        margin-top: 3px;
    }

}

/*
 * Material Steppers
 */
.mat-stepper-horizontal {

    background-color: transparent;

    &.step-label-off .mat-step-label {
        display: none;
    }

    .mat-horizontal-stepper-header {

        .mat-step-icon {
            background-color: #fff;
            color: #000;
            font-weight: bold;
            line-height: 1;
            margin-right: 0;
        }
   
        .mat-step-icon-selected {
            background-color: transparent;
            color: #fff;
            // border: 3px solid #171B45;
            box-shadow: 0 0 0 2px #fff;
        }
   
    } 

    .mat-stepper-horizontal-line {
        border-top-color: rgba(255, 255, 255, 0.4);
    }

    &.disable-nav .mat-horizontal-stepper-header {
        pointer-events: none !important;
    }

}

